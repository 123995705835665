<template>
	<div>
		<Loader v-if="formFields.loading" />
		<CForm v-if="formFields.data" @submit.prevent="checkForm">
			<div class="form-group">
				<MaterialInput
					v-if="shouldShowField('fullname')"
					id="fullname"
					v-model="form.fullname.value"
					type="text"
					:label="$t('registration.full-name')"
					:error="form.fullname.error"
				/>

				<MaterialInput
					v-if="shouldShowField('email')"
					id="email"
					v-model="form.email.value"
					type="text"
					:label="$t('registration.email')"
					:error="form.email.error"
				/>

				<MaterialInput
					v-if="shouldShowField('password')"
					id="password"
					v-model="form.password.value"
					type="password"
					:label="$t('registration.password')"
					:error="form.password.error"
				/>

				<MaterialPhoneInput
					v-if="shouldShowField('mobilenumber')"
					:default-country="form.country.value"
					:mobilenumber="form.mobilenumber.value"
					:error="form.mobilenumber.error"
					:label="$t('profile-info.phone.title')"
					placeholder="+132 345 567"
					@set-mobile-number="setMobileNumber"
				/>

				<MaterialSelect
					v-if="shouldShowField('country')"
					id="country"
					v-model="form.country.value"
					:label="$t('registration.country')"
					:error="form.country.error"
				>
					<option disabled>
						{{ $t('global.please-select') }}
					</option>
					<option v-for="(country, countryIndex) in getCountries" :key="countryIndex" :value="country.isoCode">
						{{ country.name }}
					</option>
				</MaterialSelect>
			</div>

			<div v-if="shouldShowField('accept_tc_implicit')" class="terms">
				<label class="terms__label">
					{{ $t('registration.agree_implicit') }}
					<a class="terms__link" :href="tcUrl" target="_blank" rel="noopener noreferrer">
						{{ $t('registration.terms') }}
					</a>
				</label>
			</div>

			<MaterialCheckbox
				v-if="shouldShowField('accept_tc_explicit')"
				id="tc_accepted"
				v-model="form.tc_accepted.value"
				type="checkbox"
				:label="$t('registration.agree_explicit')"
				:error="form.tc_accepted.error"
				name="tc"
			>
				<slot name="suffix">
					<a class="terms__link" :href="tcUrl" target="_blank" rel="noopener noreferrer">
						{{ $t('registration.terms') }}
					</a>
				</slot>
			</MaterialCheckbox>

			<div v-if="shouldShowField('accept_tc_explicitly')"></div>

			<div v-if="form.captchaResponse.error" class="network-error">
				<FormError error="Captcha error" />
			</div>

			<FormError v-if="networkError" :error="$t('errors.network-error')" />

			<CButton primary full-width :loading="loading" type="submit">
				{{ $t('registration.create-account') }}
			</CButton>

			<div class="sign-in">
				{{ $t('registration.have-account') }}
				<a class="sign-in__link" href="/login">
					{{ $t('registration.sign-here') }}
				</a>
			</div>
		</CForm>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import MaterialInput from '@/components/material-input/MaterialInput';
import MaterialSelect from '@/components/material-select/MaterialSelect';
import CButton from '@/shared/cbutton/CButton';
import CForm from '@/shared/cform/CForm';
import MaterialPhoneInput from '@/shared/material-phone-input/MaterialPhoneInput';
import Loader from '@/shared/loader/Loader';
import apiClient from '../../api';
import Analytics from '@/utils/analytics';
import MaterialCheckbox from '@/components/material-checkbox/MaterialCheckbox.vue';

const FormError = () => import('../../shared/forms/FormError.vue');

export default {
	name: 'RegistrationForm',
	components: {
		MaterialCheckbox,
		Loader,
		MaterialPhoneInput,
		CForm,
		CButton,
		MaterialSelect,
		MaterialInput,
		FormError,
	},
	data() {
		return {
			form: {
				fullname: {
					value: this.prefillFor('fullname', ''),
					error: null,
				},
				email: {
					value: this.prefillFor('email', ''),
					error: null,
				},
				country: {
					value: null,
					error: null,
				},
				tc_accepted: {
					value: false,
					error: null,
				},
				password: {
					value: '',
					error: null,
				},
				mobilenumber: {
					value: this.prefillFor('phone', ''),
					error: null,
					isValid: false,
				},
				captchaResponse: {
					error: null,
				},
			},
			captchaResponse: null,
			loading: false,
			tcUrl: process.env.VUE_APP_TERMS_URL,
			networkError: false,
			formFields: {
				loading: true,
				data: null,
				error: null,
			},
		};
	},
	computed: {
		...mapGetters(['getUserLanguage', 'getCountries']),
	},
	methods: {
		...mapActions(['loginWithRefreshToken']),
		async getFormFields() {
			try {
				this.formFields.loading = true;
				const { customerFieldConfigurations } = await apiClient.getSignupRealFormFields();
				this.formFields.data = customerFieldConfigurations;
			} catch (e) {
				this.formFields.error = e;
			} finally {
				this.formFields.loading = false;
			}
		},
		shouldShowField(field) {
			return Boolean(this.formFields.data.find((item) => item.field === field));
		},
		async submitForm() {
			const { fullname, email, tc_accepted, country, password, mobilenumber } = this.form;

			const { captchaResponse } = this;
			const wtreferrer = this.$route.query.fref;
			const wants_to_become_leader = Boolean(this.$route.query.leader);
			const source = this.$route.query.src;

			this.networkError = false;

			try {
				this.loading = true;

				const { refreshToken } = await apiClient.createUser({
					fullname: this.shouldShowField('fullname') ? fullname.value : undefined,
					email: this.shouldShowField('email') ? email.value : undefined,
					password: this.shouldShowField('password') ? password.value : undefined,
					country: this.shouldShowField('country') ? country.value : undefined,
					mobilenumber: this.shouldShowField('mobilenumber') ? mobilenumber.value : undefined,
					tc_accepted: this.shouldShowField('accept_tc_implicit') ? true : tc_accepted.value,
					captchaResponse,
					wtreferrer,
					wants_to_become_leader,
					communicationLanguage: this.getUserLanguage,
					step: 'SignupRealForm',
					signupCode: this.$route.query.signupCode ? this.$route.query.signupCode.toUpperCase() : undefined,
					source,
				});

				await this.loginWithRefreshToken({ refreshToken });

				Analytics.trackEvent({
					event: 'Registration',
				});

				this.$router.push({
					path: '/',
				});
			} catch (error) {
				this.recaptcha();
				if (error.response?.data?.errors) {
					error.response.data.errors.map((value) => {
						this.form[value.param].error = value.msg;

						if (value.param === 'mobilenumber') {
							this.form.mobilenumber.error = value.msg;
						}
					});
				} else {
					Sentry.captureException(error);
					this.networkError = true;
				}
			} finally {
				this.loading = false;
			}
		},

		checkForm() {
			this.clearErrors();

			const { mobilenumber } = this.form;

			if (this.shouldShowField('mobilenumber') && !mobilenumber.isValid) {
				mobilenumber.error = this.$t('profile-info.phone.invalid');
			}

			const hasErrors = Object.entries(this.form).filter((p) => p[1].error).length !== 0;

			if (!hasErrors) {
				this.submitForm();
			}
		},
		async recaptcha() {
			try {
				// (optional) Wait until recaptcha has been loaded.
				await this.$recaptchaLoaded();

				// Execute reCAPTCHA with action "login".
				this.captchaResponse = await this.$recaptcha('registration');
			} catch (e) {
				console.error(`RECAPTCHA Failed: ${e}`);
			}
		},
		async setLocale() {
			try {
				if (process.env.VUE_APP_IP_COUNTRY_PRESELECT === 'true') {
					const { countryCode } = await apiClient.fetchIPCountry();
					this.form.country.value = countryCode;
				}
			} catch (e) {
				console.error(e);
			}
		},
		setMobileNumber(event) {
			this.form.mobilenumber.value = event.number;
			this.form.mobilenumber.isValid = event.isValid;
		},
		prefillFor(attr, fallback = null) {
			return this.$route.query[`pf[${attr}]`] || fallback;
		},
		clearErrors() {
			for (const param in this.form) {
				this.form[param].error = '';
			}
			this.error = null;
			this.success = false;
		},
	},
	async beforeMount() {
		await this.recaptcha();
	},
	async mounted() {
		await this.getFormFields();
		await this.setLocale();
		this.loading = false;
	},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/style/_variables';

.terms {
	text-align: start;

	&__label {
		font-size: $caption;
		color: rgba(255, 255, 255, 0.6);
	}

	&__link {
		font-size: $caption;
	}
}

.sign-in {
	margin-top: 1rem;
	color: rgba(255, 255, 255, 0.6);

	.sign-in__link {
		font-weight: bold;
		color: #ffffff;

		&:hover {
			color: $mantis-green;
		}
	}
}
</style>
